@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  color: #3a2b2b;
  background: radial-gradient(#D9F1FC, transparent);
  font-family: 'KoHo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
    font-weight: 400;
      font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-synthesis: none;
  letter-spacing: 0.25px;
}

/* Styling for fonts */

.med {
  font-family: "KoHo", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.semibold {
  font-family: "KoHo", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.bold,
strong,
b {
  font-family: "KoHo", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.reg-italic {
  font-family: "KoHo", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.med-italic {
  font-family: "KoHo", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.bold-italic {
  font-family: "KoHo", sans-serif;
  font-weight: 600;
  font-style: italic;
}
/*End Styling for fonts */
.internal-page.state-details {
  background-color: rgba(182, 228, 248, 0.4);
}

.main-content {
  min-height: 74vh;
}

p,
article {
  font-size: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

h1 {
  font-size: 32px;
  font-family: "KoHo", sans-serif;
  font-weight: 600;
  font-style: normal;
}

h2 {
  font-size: 32px;
  margin-bottom: 12px;
  position: relative;
  border-left: 1px solid #58A4AE;
  line-height: 1.1;
  font-family: "KoHo", sans-serif;
  font-weight: 600;
  font-style: normal;
}

h2 .text {
  background: rgba(177, 225, 249, 0.4);
  padding: 0 32px 0 8px;
  border-top-right-radius: 28px;
  line-height: 100%;
  color: #58A4AE;
  font-family: "KoHo", sans-serif;
  font-weight: 600;
  font-style: normal;
}

h2 .text:after {
  background: #58A4AE;
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}

h3{
  font-size: 24px;
  font-family: "KoHo", sans-serif;
  font-weight: 600;
  font-style: normal;
}
h3.title, .para-title {
  font-size: 24px;
  font-family: "KoHo", sans-serif;
  font-weight: 500;
  font-style: normal;
}
h4 {
  font-size: 22px;
  font-family: "KoHo", sans-serif;
  font-weight: 600;
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.info {
  color: #54AF4B
}
a:hover {
  color: #58A4AE;
  transition: all 0.2s;
}

a.card-heading:hover {
  background: #384148;
  color: #fff;
  transition: all 0.2s;
}

.btn {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 32px;
  border-radius: 4px;
  max-width: fit-content;
  background: #58A4AE;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}
.btn:hover {
  background: #384148;
  color: #fff;
  transition: all 0.2s;
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
.error {
  color: #ff0000;
  font-size: 12px;
}
img {
  max-width: 100%;
  vertical-align: top;
}

/* Grid styling */
.main-wrapper {
  width: 88vw;
  max-width: 1400px;
  margin: 0 auto;
}

.h-68vh {
  min-height: 68vh;
}

.row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
}

.formWrap .wrapper {
  max-height: 98vh;
  overflow-y: auto;
}

.formWrap .wrapper svg {
  cursor: pointer;
}

.img-title {
  width: 100%;
  position: absolute;
  bottom: 24px;
  padding-left: 18px;
  left: 0;
  right: 0;
  margin: auto;
  text-shadow: 1px 0 0 #58A4AE;
  color: #fff;
}

.destination-path {
  line-height: 2;
}

.destination-path svg {
  vertical-align: middle;
  display: inline-block;
  margin-right: 18px;
}

[class*='col-'] {
  padding: 0 15px;
}

.display-inline {
  display: inline-block;
}

.global-border {
  border-bottom: 4px solid #557207;
}

.image-gallery-fullscreen-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -28px;
}

.image-gallery-fullscreen-button:hover {
  opacity: 1;
}

.image-gallery .image-gallery-right-nav,
.image-gallery .image-gallery-left-nav {
  position: absolute !important;
  z-index: 99 !important;
}

.blue-gradient {
  background-image: linear-gradient(#c6e7f8, #67b6cb);
}

@media (min-width: 768px) {
  .main-wrapper {
    width: 94vw;
  }

  p,
  article {
    line-height: 1.2;
  }

  .col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  h3 {
    font-size: 30px;
  }
}

@media(min-width: 1024px) {

  p,
  article {
    font-size: 20px;
    line-height: 1.8;
    margin-bottom: 12px;
  }
}

.overlay-card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

}

.overlay-card .details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.overlay-card:hover .details {
  opacity: 1;
  visibility: visible;
}

.overlay-card:hover:before {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  visibility: visible;
}
/* Form design */

input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea{
  width: 100%;
  padding: 12px 18px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 8px;
}